import { useEffect, useRef, useState } from 'react';
import { Modal, Slide } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../model/Hooks';
import './PromptSelector.css'
import { Prompt, selectPrompt, selectPrompts, setPrompt } from '../../../model/reducers/RemaykGenReducer';

interface UrlInputProps {
  onPromptTextChange: (promptText: string) => void
}

const UrlInput = (props: UrlInputProps) => {
  const [promptText, setPromptText] = useState<string>()

  return (
    <div className='PromptSelector-url-container'>
      <input
        className='PromptSelector-url-input'
        placeholder='Enter your custom prompt text'
        type = "text"
        onChange={evt => setPromptText(evt.target.value)}
      />
      <div className={`PromptSelector-url-done${promptText ? '' : '-disabled'}`} onClick={() => {
        if (promptText) {
          props.onPromptTextChange(promptText)
        }
      }}>
        Done
      </div>
    </div>
  )
}

export const PromptSelector = () => {
    const [showOptionList, setShowOptionList] = useState(false)
    const dispatch = useAppDispatch()

    const prompts = useAppSelector(selectPrompts)
  
    const prompt = useAppSelector(selectPrompt)
    let buttonText = prompt ? prompt.title : 'Choose a style'

    const [inputText, setInputText] = useState<string>()

    const handlePromptClick = (prompt: Prompt) => {
      setShowOptionList(false)
      dispatch(setPrompt(prompt))
    }
  
    return (
      <div className='PromptSelector-container'>
        <div className='PromptSelector-button' onClick={() => setShowOptionList(true)}>
          <div className='PromptSelector-title'>
            {buttonText}
          </div>
          <img className='PromptSelector-arrow' src='/arrow_down.png' alt="Arrow down" />
        </div>
        <Modal
          open={showOptionList}
          onClose={() => {
            setShowOptionList(false)
          }}
          sx={{
            display: "flex",
            flexDirection: 'column',
            justifyContent: "flex-end",
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.6)'
          }}
        >
          <Slide direction='up' in={showOptionList} mountOnEnter unmountOnExit>
            <div className='PromptSelector-options-container'>
              <div className="PromptSelector-options">
                <UrlInput onPromptTextChange={(promptText) => {
                  const newPrompt: Prompt = {
                    id: 'custom', // replace with actual id if available
                    title: promptText,
                    prompt: 'Custom prompt' // replace with actual prompt if available
                  };
                  dispatch(setPrompt(newPrompt));
                  setShowOptionList(false);
                }}/>
                {prompts.map(prompt => (
                  <div className="PromptSelector-catalog-song" key={prompt.id}  onClick={() => handlePromptClick(prompt)} >
                    <div className='PromptSelector-catalog-labels'>
                      <div>{prompt.title}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Slide>
        </Modal>
      </div>
    )
  }
  
  export default PromptSelector